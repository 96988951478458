/* ###### Spinner ###### */

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
	}
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: spinner-grow .75s linear infinite;
}
.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable {
  &:active, &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}
@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
  
    > div {
      display: inline-block;
      width: 51px;
      height: 51px;
      margin: 6px;
      border-radius: 50%;
      animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }
  
  @keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
  
    0% {
      transform: rotateY(0deg);
    }
  
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
  
    100% {
      transform: rotateY(3600deg);
    }
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  
    &:after {
      content: " ";
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  
    div {
      display: inline-block;
      position: absolute;
      left: 6px;
      width: 13px;
      animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  
      &:nth-child(1) {
        left: 6px;
        animation-delay: -0.24s;
      }
  
      &:nth-child(2) {
        left: 26px;
        animation-delay: -0.12s;
      }
  
      &:nth-child(3) {
        left: 45px;
        animation-delay: 0;
      }
    }
  }
  
  @keyframes lds-facebook {
    0% {
      top: 6px;
      height: 51px;
    }
  
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
  
  .lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;
  
    div {
      top: 23px;
      left: 19px;
      position: absolute;
      width: 26px;
      height: 26px;
      animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  
      &:after {
        content: " ";
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        top: -17px;
        border-radius: 50% 50% 0 0;
      }
  
      &:before {
        content: " ";
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        left: -17px;
        border-radius: 50% 0 0 50%;
      }
    }
  }
  
  @keyframes lds-heart {
    0% {
      transform: scale(0.95);
    }
  
    5% {
      transform: scale(1.1);
    }
  
    39% {
      transform: scale(0.85);
    }
  
    45% {
      transform: scale(1);
    }
  
    60% {
      transform: scale(0.95);
    }
  
    100% {
      transform: scale(0.9);
    }
  }
  
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  
    div {
      position: absolute;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
  
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
  
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  
    div {
      transform-origin: 32px 32px;
      animation: lds-spinner 1.2s linear infinite;
  
      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 29px;
        width: 5px;
        height: 14px;
        border-radius: 20%;
      }
  
      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
  
      &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }
  
      &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }
  
      &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }
  
      &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }
  
      &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }
  
      &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }
  
      &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }
  
      &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }
  
      &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }
  
      &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }
  
      &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
    }
  }
  
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .pulse {
    display: block;
    position: absolute;
    top: 6px;
    right: 17px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #f3ca56;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(243, 202, 86, 0.9);
    animation: pulse 2s infinite;
  
    &:hover {
      animation: none;
    }
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.9);
    }
  
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
    }
  
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
    }
  }
  
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.9);
      box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.7);
    }
  
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
      box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
    }
  
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
      box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
    }
  }
  
  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
  
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, $black 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform 0.5s, opacity 1s;
    }
  
    &:active:after {
      transform: scale(0, 0);
      opacity: .2;
      transition: 0s;
    }
  }
  

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
  
/* ###### Spinner ###### */