/* ###### Table  ###### */

table.table {
  width: 100%;
  margin-bottom: 1rem;
  color: $color;
  // table-layout: fixed;

  th,
  td {
    // padding: 0.75rem;
    vertical-align: middle;
    line-height: 1.462;
    border-top: 0;
    // padding: 9px 15px;
  }

  tbody tr td{padding: 8px 6px;}

  thead tr th {
    vertical-align: bottom;
    border-bottom: 1px solid #e8e8f7;
    padding: 10px 6px !important;
    font-weight: bold;
    white-space: nowrap;
  }

  tfoot tr td{padding: 10px 6px !important; border-color: #e8e8f7 !important;}

  tbody + tbody {
    border-top: 1px solid #e8e8f7;
  }

  thead {
    th,
    td {
      color: #434b63;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-bottom-width: 1px;
      border-top-width: 0;
      padding: 11px;
    }
  }

  tbody tr {
    background-color: $white-5;

    th {
      font-weight: 500;
    }
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem;
  }
}

.table-bordered {
  border: 1px solid #e8e8f7;

  th,
  td {
    border: 1px solid #e8e8f7;
  }

  // thead {
  //   th,
  //   td {
  //     border-bottom-width: 2px;
  //     border-top-width: 1px;
  //     padding-top: 0.75rem;
  //     padding-bottom: 0.75rem;
  //     background-color: #efefff;
  //   }
  // }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: #f8f8ff;
}

.table-primary {
  background-color: #c6d4ff;

  > {
    th,
    td {
      background-color: #c6d4ff;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #95afff;
  }
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;

  > {
    td,
    th {
      background-color: #adc1ff;
    }
  }
}

.table-secondary {
  background-color: #d9dde5;

  > {
    th,
    td {
      background-color: #d9dde5;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #b9c1ce;
  }
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;

  > {
    td,
    th {
      background-color: #cacfdb;
    }
  }
}

.table-success {
  background-color: #c8e9b8;

  > {
    th,
    td {
      background-color: #c8e9b8;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #99d67b;
  }
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;

  > {
    td,
    th {
      background-color: #b9e3a5;
    }
  }
}

.table-info {
  background-color: #bee5eb;

  > {
    th,
    td {
      background-color: #bee5eb;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #86cfda;
  }
}

.table-hover .table-info:hover {
  background-color: #abdde5;

  > {
    td,
    th {
      background-color: #abdde5;
    }
  }
}

.table-warning {
  background-color: #ffeeba;

  > {
    th,
    td {
      background-color: #ffeeba;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #ffdf7e;
  }
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;

  > {
    td,
    th {
      background-color: #ffe8a1;
    }
  }
}

.table-danger {
  background-color: #f5c6cb;

  > {
    th,
    td {
      background-color: #f5c6cb;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #ed969e;
  }
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;

  > {
    td,
    th {
      background-color: #f1b0b7;
    }
  }
}

.table-light {
  background-color: #fcfcfd;

  > {
    th,
    td {
      background-color: #fcfcfd;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #f9fafb;
  }
}

.table-hover .table-light:hover {
  background-color: #ededf3;

  > {
    td,
    th {
      background-color: #ededf3;
    }
  }
}

.table-dark {
  color: $white;
  background-color: $dark;
  > {
    th,
    td {
      background-color: #c8ccd3;
    }
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border-color: #49597b;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &.table-hover tbody tr:hover {
    color: $white;
    background-color: rgba(255, 255, 255, 0.075);
  }
}

.table-hover .table-dark:hover {
  background-color: #babfc8;

  > {
    td,
    th {
      background-color: #babfc8;
    }
  }
}

.table-active {
  background-color: rgba(0, 0, 0, 0.075);

  > {
    th,
    td {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);

  > {
    td,
    th {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table {
  .thead-dark th {
    color: $white;
    background-color: $dark;
    border-color: #49597b;
  }

  .thead-light th {
    color: #3c4858;
    background-color: $background;
    border-color: $background;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: a;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  > .table-bordered {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #dddde933;
}

.table.dtr-details {
  margin-bottom: 0;
}

.table-shopping-cart .media {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
}

/* ###### Table  ###### */

/* #### Custom ####*/

.dataTable-wrapper.no-footer .dataTable-container,
.table {
  border: none;
}
.dataTable-input,
.dataTable-selector {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  outline: 0;
}

.table thead {
  box-shadow: 0 -1px 10px #cacaca;
}
// .table thead tr th {
//   color: #2a9742;
// }

.dataTable-top {
  padding: 0;
}
.dataTable-info {
  opacity: 0.5;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tfoot > tr > td,
.dataTable-table > thead > tr > td {
  padding: 9px 15px !important;
}

.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > th {
  padding: 9px 26px !important;
}

table.table-bordered.dataTable-table tbody th,
table.table-bordered.dataTable-table tbody td {
  border-top-width: 0 !important;
}
.dataTable-table > thead > tr > th,
.dataTable-table > tbody > tr > td {
  border-bottom: 1px solid #e8e8f7 !important;
}

table.table-bordered.dataTable-table th,
table.table-bordered.dataTable-table td {
  border-left-width: 0;
}

table.table-bordered.dataTable-table th:last-child,
table.table-bordered.dataTable-table td:last-child {
  border-right-width: 0;
}

.table-bordered thead th,
.table-bordered thead td {
  background: transparent;
}
.dataTable-pagination {
  white-space: nowrap;
  border: 1px solid #dadada;
  border-radius: 6px;
  overflow: hidden;
}
.dataTable-pagination li:first-child a {
  border-left: 0;
}
.dataTable-pagination li a {
  color: #444;
  cursor: pointer;
  background-color: #fff;
  border-left: 1px solid #dadada;
}
.dataTable-pagination a:hover {
  background-color: #f5f5f5;
  color: #2a9742;
}
.dataTable-pagination a {
  margin: 0;
}
.dataTable-pagination li.active a {
  background-color: #2a9742;
  border-color: #2a9742;
  color: #fff;
  cursor: pointer;
}
.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #2a9742;
}

.ngx-datatable.scroll-vertical {
  height: 80vh;
}

.dataTable-container {
  height: 100% !important;
  width: 100%;
  overflow: auto;
}

// CUSTOM DATATABLE

td .btn {
  padding: 4px;
  width: 24px;
  height: 24px;
  line-height: 0;
  display: inline-block;
  min-height: fit-content;
  margin: -6px 0;
  border-radius: 50%;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
  right: -16px;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
  border: none;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer {
  border-bottom: 0 !important;
}

.dataTables_wrapper .dataTables_paginate {
  span .paginate_button {
    margin: 10px 0 !important;
    border: 1px solid #0002 !important;
    border-radius: 0px !important;
    background: #fff !important;

    &.current{
      color: white !important;
      background-color: var(--success) !important;
    }

    &:not(:first-child) {
      border-left: none !important;
    }

    &:first-child {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
    &:last-child {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
}

.dataTables_paginate {
  // padding: 0 15px;
  padding-top: 0 !important; 

  .paginate_button:not(.current){
    border: 1px solid #0002 !important;
    border-radius: 6px !important;
    margin: 10px 5px !important;

    &:last-child{margin-right: 0 !important;}
    &:first-child{margin-left: 0 !important;}
  }
}
.dataTables_info{
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  opacity: 0.7;
}

table tr{th,td{&:last-child{border-right: none;}}}

.table-bordered{tr{th,td{&:first-child{border-left: 0 !important;}}}}

.dataTables_wrapper ._pagination .dataTables_paginate .paginate_button{
  &:not(.current){
    &:not(.disabled):hover{
      background: #0000000a !important;
      color: #000 !important;
    }
  }
  &.current{&, &:hover{color: white !important}}

  &:active{
    box-shadow: inset 0 0 3px #000a;
  }
}