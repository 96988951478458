/*------ Icons List ------*/

.flag {
    width: 1.6rem;
    height: 1.2rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.flag-ad {
    background-image: url("../img/flags/ad.svg");
}

.flag-ae {
    background-image: url("../img/flags/ae.svg");
}

.flag-af {
    background-image: url("../img/flags/af.svg");
}

.flag-ag {
    background-image: url("../img/flags/ag.svg");
}

.flag-ai {
    background-image: url("../img/flags/ai.svg");
}

.flag-al {
    background-image: url("../img/flags/al.svg");
}

.flag-am {
    background-image: url("../img/flags/am.svg");
}

.flag-ao {
    background-image: url("../img/flags/ao.svg");
}

.flag-aq {
    background-image: url("../img/flags/aq.svg");
}

.flag-ar {
    background-image: url("../img/flags/ar.svg");
}

.flag-as {
    background-image: url("../img/flags/as.svg");
}

.flag-at {
    background-image: url("../img/flags/at.svg");
}

.flag-au {
    background-image: url("../img/flags/au.svg");
}

.flag-aw {
    background-image: url("../img/flags/aw.svg");
}

.flag-ax {
    background-image: url("../img/flags/ax.svg");
}

.flag-az {
    background-image: url("../img/flags/az.svg");
}

.flag-ba {
    background-image: url("../img/flags/ba.svg");
}

.flag-bb {
    background-image: url("../img/flags/bb.svg");
}

.flag-bd {
    background-image: url("../img/flags/bd.svg");
}

.flag-be {
    background-image: url("../img/flags/be.svg");
}

.flag-bf {
    background-image: url("../img/flags/bf.svg");
}

.flag-bg {
    background-image: url("../img/flags/bg.svg");
}

.flag-bh {
    background-image: url("../img/flags/bh.svg");
}

.flag-bi {
    background-image: url("../img/flags/bi.svg");
}

.flag-bj {
    background-image: url("../img/flags/bj.svg");
}

.flag-bl {
    background-image: url("../img/flags/bl.svg");
}

.flag-bm {
    background-image: url("../img/flags/bm.svg");
}

.flag-bn {
    background-image: url("../img/flags/bn.svg");
}

.flag-bo {
    background-image: url("../img/flags/bo.svg");
}

.flag-bq {
    background-image: url("../img/flags/bq.svg");
}

.flag-br {
    background-image: url("../img/flags/br.svg");
}

.flag-bs {
    background-image: url("../img/flags/bs.svg");
}

.flag-bt {
    background-image: url("../img/flags/bt.svg");
}

.flag-bv {
    background-image: url("../img/flags/bv.svg");
}

.flag-bw {
    background-image: url("../img/flags/bw.svg");
}

.flag-by {
    background-image: url("../img/flags/by.svg");
}

.flag-bz {
    background-image: url("../img/flags/bz.svg");
}

.flag-ca {
    background-image: url("../img/flags/ca.svg");
}

.flag-cc {
    background-image: url("../img/flags/cc.svg");
}

.flag-cd {
    background-image: url("../img/flags/cd.svg");
}

.flag-cf {
    background-image: url("../img/flags/cf.svg");
}

.flag-cg {
    background-image: url("../img/flags/cg.svg");
}

.flag-ch {
    background-image: url("../img/flags/ch.svg");
}

.flag-ci {
    background-image: url("../img/flags/ci.svg");
}

.flag-ck {
    background-image: url("../img/flags/ck.svg");
}

.flag-cl {
    background-image: url("../img/flags/cl.svg");
}

.flag-cm {
    background-image: url("../img/flags/cm.svg");
}

.flag-cn {
    background-image: url("../img/flags/cn.svg");
}

.flag-co {
    background-image: url("../img/flags/co.svg");
}

.flag-cr {
    background-image: url("../img/flags/cr.svg");
}

.flag-cu {
    background-image: url("../img/flags/cu.svg");
}

.flag-cv {
    background-image: url("../img/flags/cv.svg");
}

.flag-cw {
    background-image: url("../img/flags/cw.svg");
}

.flag-cx {
    background-image: url("../img/flags/cx.svg");
}

.flag-cy {
    background-image: url("../img/flags/cy.svg");
}

.flag-cz {
    background-image: url("../img/flags/cz.svg");
}

.flag-de {
    background-image: url("../img/flags/de.svg");
}

.flag-dj {
    background-image: url("../img/flags/dj.svg");
}

.flag-dk {
    background-image: url("../img/flags/dk.svg");
}

.flag-dm {
    background-image: url("../img/flags/dm.svg");
}

.flag-do {
    background-image: url("../img/flags/do.svg");
}

.flag-dz {
    background-image: url("../img/flags/dz.svg");
}

.flag-ec {
    background-image: url("../img/flags/ec.svg");
}

.flag-ee {
    background-image: url("../img/flags/ee.svg");
}

.flag-eg {
    background-image: url("../img/flags/eg.svg");
}

.flag-eh {
    background-image: url("../img/flags/eh.svg");
}

.flag-er {
    background-image: url("../img/flags/er.svg");
}

.flag-es {
    background-image: url("../img/flags/es.svg");
}

.flag-et {
    background-image: url("../img/flags/et.svg");
}

.flag-eu {
    background-image: url("../img/flags/eu.svg");
}

.flag-fi {
    background-image: url("../img/flags/fi.svg");
}

.flag-fj {
    background-image: url("../img/flags/fj.svg");
}

.flag-fk {
    background-image: url("../img/flags/fk.svg");
}

.flag-fm {
    background-image: url("../img/flags/fm.svg");
}

.flag-fo {
    background-image: url("../img/flags/fo.svg");
}

.flag-fr {
    background-image: url("../img/flags/fr.svg");
}

.flag-ga {
    background-image: url("../img/flags/ga.svg");
}

.flag-gb-eng {
    background-image: url("../img/flags/gb-eng.svg");
}

.flag-gb-nir {
    background-image: url("../img/flags/gb-nir.svg");
}

.flag-gb-sct {
    background-image: url("../img/flags/gb-sct.svg");
}

.flag-gb-wls {
    background-image: url("../img/flags/gb-wls.svg");
}

.flag-gb {
    background-image: url("../img/flags/gb.svg");
}

.flag-gd {
    background-image: url("../img/flags/gd.svg");
}

.flag-ge {
    background-image: url("../img/flags/ge.svg");
}

.flag-gf {
    background-image: url("../img/flags/gf.svg");
}

.flag-gg {
    background-image: url("../img/flags/gg.svg");
}

.flag-gh {
    background-image: url("../img/flags/gh.svg");
}

.flag-gi {
    background-image: url("../img/flags/gi.svg");
}

.flag-gl {
    background-image: url("../img/flags/gl.svg");
}

.flag-gm {
    background-image: url("../img/flags/gm.svg");
}

.flag-gn {
    background-image: url("../img/flags/gn.svg");
}

.flag-gp {
    background-image: url("../img/flags/gp.svg");
}

.flag-gq {
    background-image: url("../img/flags/gq.svg");
}

.flag-gr {
    background-image: url("../img/flags/gr.svg");
}

.flag-gs {
    background-image: url("../img/flags/gs.svg");
}

.flag-gt {
    background-image: url("../img/flags/gt.svg");
}

.flag-gu {
    background-image: url("../img/flags/gu.svg");
}

.flag-gw {
    background-image: url("../img/flags/gw.svg");
}

.flag-gy {
    background-image: url("../img/flags/gy.svg");
}

.flag-hk {
    background-image: url("../img/flags/hk.svg");
}

.flag-hm {
    background-image: url("../img/flags/hm.svg");
}

.flag-hn {
    background-image: url("../img/flags/hn.svg");
}

.flag-hr {
    background-image: url("../img/flags/hr.svg");
}

.flag-ht {
    background-image: url("../img/flags/ht.svg");
}

.flag-hu {
    background-image: url("../img/flags/hu.svg");
}

.flag-id {
    background-image: url("../img/flags/id.svg");
}

.flag-ie {
    background-image: url("../img/flags/ie.svg");
}

.flag-il {
    background-image: url("../img/flags/il.svg");
}

.flag-im {
    background-image: url("../img/flags/im.svg");
}

.flag-in {
    background-image: url("../img/flags/in.svg");
}

.flag-io {
    background-image: url("../img/flags/io.svg");
}

.flag-iq {
    background-image: url("../img/flags/iq.svg");
}

.flag-ir {
    background-image: url("../img/flags/ir.svg");
}

.flag-is {
    background-image: url("../img/flags/is.svg");
}

.flag-it {
    background-image: url("../img/flags/it.svg");
}

.flag-je {
    background-image: url("../img/flags/je.svg");
}

.flag-jm {
    background-image: url("../img/flags/jm.svg");
}

.flag-jo {
    background-image: url("../img/flags/jo.svg");
}

.flag-jp {
    background-image: url("../img/flags/jp.svg");
}

.flag-ke {
    background-image: url("../img/flags/ke.svg");
}

.flag-kg {
    background-image: url("../img/flags/kg.svg");
}

.flag-kh {
    background-image: url("../img/flags/kh.svg");
}

.flag-ki {
    background-image: url("../img/flags/ki.svg");
}

.flag-km {
    background-image: url("../img/flags/km.svg");
}

.flag-kn {
    background-image: url("../img/flags/kn.svg");
}

.flag-kp {
    background-image: url("../img/flags/kp.svg");
}

.flag-kr {
    background-image: url("../img/flags/kr.svg");
}

.flag-kw {
    background-image: url("../img/flags/kw.svg");
}

.flag-ky {
    background-image: url("../img/flags/ky.svg");
}

.flag-kz {
    background-image: url("../img/flags/kz.svg");
}

.flag-la {
    background-image: url("../img/flags/la.svg");
}

.flag-lb {
    background-image: url("../img/flags/lb.svg");
}

.flag-lc {
    background-image: url("../img/flags/lc.svg");
}

.flag-li {
    background-image: url("../img/flags/li.svg");
}

.flag-lk {
    background-image: url("../img/flags/lk.svg");
}

.flag-lr {
    background-image: url("../img/flags/lr.svg");
}

.flag-ls {
    background-image: url("../img/flags/ls.svg");
}

.flag-lt {
    background-image: url("../img/flags/lt.svg");
}

.flag-lu {
    background-image: url("../img/flags/lu.svg");
}

.flag-lv {
    background-image: url("../img/flags/lv.svg");
}

.flag-ly {
    background-image: url("../img/flags/ly.svg");
}

.flag-ma {
    background-image: url("../img/flags/ma.svg");
}

.flag-mc {
    background-image: url("../img/flags/mc.svg");
}

.flag-md {
    background-image: url("../img/flags/md.svg");
}

.flag-me {
    background-image: url("../img/flags/me.svg");
}

.flag-mf {
    background-image: url("../img/flags/mf.svg");
}

.flag-mg {
    background-image: url("../img/flags/mg.svg");
}

.flag-mh {
    background-image: url("../img/flags/mh.svg");
}

.flag-mk {
    background-image: url("../img/flags/mk.svg");
}

.flag-ml {
    background-image: url("../img/flags/ml.svg");
}

.flag-mm {
    background-image: url("../img/flags/mm.svg");
}

.flag-mn {
    background-image: url("../img/flags/mn.svg");
}

.flag-mo {
    background-image: url("../img/flags/mo.svg");
}

.flag-mp {
    background-image: url("../img/flags/mp.svg");
}

.flag-mq {
    background-image: url("../img/flags/mq.svg");
}

.flag-mr {
    background-image: url("../img/flags/mr.svg");
}

.flag-ms {
    background-image: url("../img/flags/ms.svg");
}

.flag-mt {
    background-image: url("../img/flags/mt.svg");
}

.flag-mu {
    background-image: url("../img/flags/mu.svg");
}

.flag-mv {
    background-image: url("../img/flags/mv.svg");
}

.flag-mw {
    background-image: url("../img/flags/mw.svg");
}

.flag-mx {
    background-image: url("../img/flags/mx.svg");
}

.flag-my {
    background-image: url("../img/flags/my.svg");
}

.flag-mz {
    background-image: url("../img/flags/mz.svg");
}

.flag-na {
    background-image: url("../img/flags/na.svg");
}

.flag-nc {
    background-image: url("../img/flags/nc.svg");
}

.flag-ne {
    background-image: url("../img/flags/ne.svg");
}

.flag-nf {
    background-image: url("../img/flags/nf.svg");
}

.flag-ng {
    background-image: url("../img/flags/ng.svg");
}

.flag-ni {
    background-image: url("../img/flags/ni.svg");
}

.flag-nl {
    background-image: url("../img/flags/nl.svg");
}

.flag-no {
    background-image: url("../img/flags/no.svg");
}

.flag-np {
    background-image: url("../img/flags/np.svg");
}

.flag-nr {
    background-image: url("../img/flags/nr.svg");
}

.flag-nu {
    background-image: url("../img/flags/nu.svg");
}

.flag-nz {
    background-image: url("../img/flags/nz.svg");
}

.flag-om {
    background-image: url("../img/flags/om.svg");
}

.flag-pa {
    background-image: url("../img/flags/pa.svg");
}

.flag-pe {
    background-image: url("../img/flags/pe.svg");
}

.flag-pf {
    background-image: url("../img/flags/pf.svg");
}

.flag-pg {
    background-image: url("../img/flags/pg.svg");
}

.flag-ph {
    background-image: url("../img/flags/ph.svg");
}

.flag-pk {
    background-image: url("../img/flags/pk.svg");
}

.flag-pl {
    background-image: url("../img/flags/pl.svg");
}

.flag-pm {
    background-image: url("../img/flags/pm.svg");
}

.flag-pn {
    background-image: url("../img/flags/pn.svg");
}

.flag-pr {
    background-image: url("../img/flags/pr.svg");
}

.flag-ps {
    background-image: url("../img/flags/ps.svg");
}

.flag-pt {
    background-image: url("../img/flags/pt.svg");
}

.flag-pw {
    background-image: url("../img/flags/pw.svg");
}

.flag-py {
    background-image: url("../img/flags/py.svg");
}

.flag-qa {
    background-image: url("../img/flags/qa.svg");
}

.flag-re {
    background-image: url("../img/flags/re.svg");
}

.flag-ro {
    background-image: url("../img/flags/ro.svg");
}

.flag-rs {
    background-image: url("../img/flags/rs.svg");
}

.flag-ru {
    background-image: url("../img/flags/ru.svg");
}

.flag-rw {
    background-image: url("../img/flags/rw.svg");
}

.flag-sa {
    background-image: url("../img/flags/sa.svg");
}

.flag-sb {
    background-image: url("../img/flags/sb.svg");
}

.flag-sc {
    background-image: url("../img/flags/sc.svg");
}

.flag-sd {
    background-image: url("../img/flags/sd.svg");
}

.flag-se {
    background-image: url("../img/flags/se.svg");
}

.flag-sg {
    background-image: url("../img/flags/sg.svg");
}

.flag-sh {
    background-image: url("../img/flags/sh.svg");
}

.flag-si {
    background-image: url("../img/flags/si.svg");
}

.flag-sj {
    background-image: url("../img/flags/sj.svg");
}

.flag-sk {
    background-image: url("../img/flags/sk.svg");
}

.flag-sl {
    background-image: url("../img/flags/sl.svg");
}

.flag-sm {
    background-image: url("../img/flags/sm.svg");
}

.flag-sn {
    background-image: url("../img/flags/sn.svg");
}

.flag-so {
    background-image: url("../img/flags/so.svg");
}

.flag-sr {
    background-image: url("../img/flags/sr.svg");
}

.flag-ss {
    background-image: url("../img/flags/ss.svg");
}

.flag-st {
    background-image: url("../img/flags/st.svg");
}

.flag-sv {
    background-image: url("../img/flags/sv.svg");
}

.flag-sx {
    background-image: url("../img/flags/sx.svg");
}

.flag-sy {
    background-image: url("../img/flags/sy.svg");
}

.flag-sz {
    background-image: url("../img/flags/sz.svg");
}

.flag-tc {
    background-image: url("../img/flags/tc.svg");
}

.flag-td {
    background-image: url("../img/flags/td.svg");
}

.flag-tf {
    background-image: url("../img/flags/tf.svg");
}

.flag-tg {
    background-image: url("../img/flags/tg.svg");
}

.flag-th {
    background-image: url("../img/flags/th.svg");
}

.flag-tj {
    background-image: url("../img/flags/tj.svg");
}

.flag-tk {
    background-image: url("../img/flags/tk.svg");
}

.flag-tl {
    background-image: url("../img/flags/tl.svg");
}

.flag-tm {
    background-image: url("../img/flags/tm.svg");
}

.flag-tn {
    background-image: url("../img/flags/tn.svg");
}

.flag-to {
    background-image: url("../img/flags/to.svg");
}

.flag-tr {
    background-image: url("../img/flags/tr.svg");
}

.flag-tt {
    background-image: url("../img/flags/tt.svg");
}

.flag-tv {
    background-image: url("../img/flags/tv.svg");
}

.flag-tw {
    background-image: url("../img/flags/tw.svg");
}

.flag-tz {
    background-image: url("../img/flags/tz.svg");
}

.flag-ua {
    background-image: url("../img/flags/ua.svg");
}

.flag-ug {
    background-image: url("../img/flags/ug.svg");
}

.flag-um {
    background-image: url("../img/flags/um.svg");
}

.flag-un {
    background-image: url("../img/flags/un.svg");
}

.flag-us {
    background-image: url("../img/flags/us.svg");
}

.flag-uy {
    background-image: url("../img/flags/uy.svg");
}

.flag-uz {
    background-image: url("../img/flags/uz.svg");
}

.flag-va {
    background-image: url("../img/flags/va.svg");
}

.flag-vc {
    background-image: url("../img/flags/vc.svg");
}

.flag-ve {
    background-image: url("../img/flags/ve.svg");
}

.flag-vg {
    background-image: url("../img/flags/vg.svg");
}

.flag-vi {
    background-image: url("../img/flags/vi.svg");
}

.flag-vn {
    background-image: url("../img/flags/vn.svg");
}

.flag-vu {
    background-image: url("../img/flags/vu.svg");
}

.flag-wf {
    background-image: url("../img/flags/wf.svg");
}

.flag-ws {
    background-image: url("../img/flags/ws.svg");
}

.flag-ye {
    background-image: url("../img/flags/ye.svg");
}

.flag-yt {
    background-image: url("../img/flags/yt.svg");
}

.flag-za {
    background-image: url("../img/flags/za.svg");
}

.flag-zm {
    background-image: url("../img/flags/zm.svg");
}

.flag-zw {
    background-image: url("../img/flags/zw.svg");
}

.payment {
    width: 2.5rem;
    height: 1.5rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}


svg {
    -ms-touch-action: none;
    touch-action: none;
}